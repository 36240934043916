import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Hayden Disley - Portfolio', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Hayden Disley.',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'Hey! I’m Hayden Disley, a full-stack developer and recent Northcoders graduate located in Manchester. I have always had a curiosity for coding and what it can achieve. With plenty of spare time on my hands during Lockdown 1, I enrolled on Harvard University’s CS50 introduction to Computer Science Course.',
  paragraphTwo:
    'It was then that I found my passion for coding and decided to pursue it as a career. I made the decision to join Northcoders in August 2020 and graduated the bootcamp in November. This gave me a wide range of full-stack skills and the opportunity to use multiple technologies, plus spiked a particular interest in mobile development. Since then I have been looking for the right company to work with that sparks my interest and allows me to expand my knowledge.',
  paragraphThree:
    'Tech Used: Javascript, Express, Node.js, PostgreSQL, Axios,  GraphQL, Jest Testing, Knex, Supertest, MongoDB, C, Python, React, React Native, Javascript, Expo, CSS and Figma.',
  resume: 'https://pdfhost.io/v/yKXFm6m.W_HaydenDisleyCVpdf.pdf', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'MatesRates.png',
    title: 'Mates Rates',
    info:
      'As a member of a team of four, we created a full-stack mobile application that allows you and your friends to rate restaurants and view their reccomendations. We challenged ourselves to use 3 new languages/frameworks we hadn’t experienced: React Native, GraphQL and MongoDB.',
    info2: '',
    url: 'https://www.youtube.com/watch?v=O90sEFBfCJc&feature=youtu.be',
    repo: 'https://github.com/hjdisley/MatesRates', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'TheNews.png',
    title: 'The News',
    info:
      'I Developed a mobile application using Expo and React Native that gathers data from the News API. This app implements React Native Navigation, Fetch API and React Native Elements. This app is hosted using Expo, to try on iOS click the Live Demo link.',
    info2: '',
    url: 'https://expo.io/@disley01/projects/TheNews',
    repo: 'https://github.com/hjdisley/TheNews', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'AmazonClone.png',
    title: 'Amazon Clone',
    info:
      'An Amazon clone, built in React, that allowed me to work on technology that I didnt have the chance to implement during my final project. The first of these being Firebase which gave the site authentication and database and React Context which allowed me to hold state externally. Also used Express.js for the backend.',
    info2: '',
    url: 'https://youtu.be/9jQhj0HREPg',
    repo: 'https://github.com/hjdisley/amazon-clone', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'haydendisley1@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/haydendisley/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://www.github.com/hjdisley',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
